import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Redux
import { selectSelectedLocation } from '../../redux/slices/employerSlice'

// Components

// Styles
import './AppSideNav.scss'

function AppSideNav({ username }) {
    const { isRootCompany, role } = useSelector(selectSelectedLocation)
    const isRootCompanyAndIsAdmin = isRootCompany && role === 'admin'

    return (
        <div className="leftside-menu leftside-menu-detached show fit-content">
            <div className="leftbar-user">
                <span>
                    <img src={`https://ui-avatars.com/api/?name=${username}&length=1&background=1de9b6`} alt="user icon" height="42" className="rounded-circle shadow-sm" />
                    <span className="leftbar-user-name ms-0">{username}</span>
                </span>
            </div>

            <ul className="side-nav">
                <li className="side-nav-item">
                    <Link to="/employer" className="side-nav-link d-flex align-items-center">
                        <div className="sidenav-app d-flex align-items-center justify-items-center text-white bg-secondary">
                            <ion-icon size="medium" name="home"></ion-icon>
                        </div>
                        <span className="ms-1">Dashboard</span>
                    </Link>
                </li>

                <li className="side-nav-title side-nav-item">Tasks</li>
                <li className="side-nav-item">
                    <a href="https://primeoccmed.com/forms/#1546189734451-717c7091-37fe" target="_blank" className="side-nav-link d-flex align-items-center">
                        <div className="sidenav-app d-flex align-items-center justify-items-center text-white bg-success">
                            <ion-icon name="bag-add"></ion-icon>
                        </div>
                        <span className="ms-1"> New Service Request </span>
                    </a>
                </li>

                {role === 'admin' && (
                    <>
                        <li className="side-nav-title side-nav-item">Company</li>
                        <li className="side-nav-item">
                            <Link to="/employer/profile-setup" className="side-nav-link d-flex align-items-center">
                                <div className="sidenav-app d-flex align-items-center justify-items-center text-white bg-primary">
                                    <ion-icon name="cog"></ion-icon>
                                </div>
                                <span className="ms-1"> Organization </span>
                            </Link>
                        </li>
                    </>
                )}
                {isRootCompanyAndIsAdmin && (
                    <li className="side-nav-item">
                        <Link to="/employer/settings/users" className="side-nav-link d-flex align-items-center">
                            <div className="sidenav-app d-flex align-items-center justify-items-center text-white bg-primary">
                                <ion-icon name="people"></ion-icon>
                            </div>
                            <span className="ms-1">Users</span>
                        </Link>
                    </li>
                )}
            </ul>
            <div className="help-box help-box-light text-center mt-5">
                <h5 className="mt-1">Open Beta</h5>
                <p className="mb-3">This application is under active development. If an issue persists, please use the legacy employer portal.</p>
                <a rel="noreferrer" target="_blank" href="https://pgs.prime-eme.com/" className="btn btn-outline-primary btn-sm">
                    EME Employer Portal
                </a>
                <br />
                <br />
                <ion-icon name="mail-outline" size="large"></ion-icon>
                <h5 className="mt-1">Escalate Issue</h5>
                <p className="mb-3">Contact Support For Help With An Issue</p>
                <a rel="noreferrer" target="_blank" href="mailto:eme.support@primeoccmed.com?subject=EMPLOYER%3A%20Issue%20with%20employer%20portal" className="btn btn-outline-primary btn-sm">
                    Contact Support
                </a>
                <p className="small mt-5">0.0.2c</p>
            </div>
        </div>
    )
}

export default AppSideNav
